import React from 'react';
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './static-pages.scss'
export default function CarHistoryReport() {
  return (
    <div id="bike-history-report" className="static-page-wrapper">
      <Container className="container">
        <section className="section-wrapper">
          <h1 className="section-heading">Bike History Report - Why and How</h1>
          <Button variant="contained" color="primary" href="/" style={{float:'right', color:'#fff', backgroundColor:'#2598fb'}}>Check Car History</Button>
          <p>Are you planning to purchase a bike, but stuck in between the decision of whether to buy a brand-new one or a used model? Frankly speaking, both new and used bikes come with their own limitations as well as advantages. While a new bike gives you complete peace of mind as we know that the product is brand-new and unused, and even if anything goes wrong, the company will cover for it, a second-hand bike will be available for a much lesser amount than a new model and it seems to be a good deal if you have all bike information and the used bike is sold in good condition. As far as the cons are concerned, the only disadvantage with a new bike is that it will cost you a lot more than a used one, and it will lose its value rapidly as soon as you drive it out of the showroom. On the other hand, a used bike seems to be beneficial as it has already gone through the major phase of its value depreciation and it serves as more value for money product if bought in a good condition. Make sure that you are aware of the bike registration details and bike owner details, while choosing a used bike.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">How to Know the Real Condition of A Used Bike?</h2>
          <p>To be absolutely sure about the condition of any used bike, you need to go through a proper background check. This is where ‘History’ from Droom, India’s first and only online marketplace for automobiles and related services, comes in. As an online tool of checking used vehicle history, ‘History’ gives thorough information about the background of any bike. You can check and get the bike history report or premium report provided by History. This report will help to get two wheeler registration details by entering the registration number of any pre-owned bike.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">How to Get Bike Owner Details?</h2>
          <p>Each owner of the vehicle is bound to register with RTO or Regional Transport Office, where they keep a record of all the vehicles including cars and two wheelers. For example, if you have a bike, you must register the bike details and owner details, either manually or through online bike registration. The information can be tracked by anyone who wants to check bike registration, before purchasing the used bike. In History, you need to submit the valid registration number of the bike and within 10 secs you will get the motorcycle registration details along with bike owner details, accident claim history, insurance history, flood damage history, loan history, theft history, and service logs history.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">How to Take the Decision of Used Bike Purchase?</h2>
          <p>With the help of bike history premium report from History, you will get all the necessary information about any bike. So, this will assist you to make a more informed decision while buying a second-hand bike as it will provide you with information on bike registration details, bike loan history, bike’s fitness report validity, the real age of the used bike, blacklist history (if any), etc. So, do thorough research on a used bike before buying it because you never know what is hidden under the shining outer appearance of the second-hand bike you are going to purchase.</p>
        </section>
      </Container>
    </div>
  );
}
