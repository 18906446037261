import React from 'react';
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './static-pages.scss'
export default function ScooterHistoryReport() {
  return (
    <div id="scooter-history-report" className="static-page-wrapper">
      <Container className="container">
        <section className="section-wrapper">
          <h1 className="section-heading">Why You Need Scooter History Report Before Buying A Used Scooter?</h1>
          <Button variant="contained" color="primary" href="/" style={{float:'right', color:'#fff', backgroundColor:'#2598fb'}}>Check Car History</Button>
          <p>Are you planning to buy a used scooter, but you are not sure about its quality? Are you worried about any inner damage or hidden issues with the used scooter you are going to buy? This is true that you need to check any used scooter thoroughly before buying it. You need to check scooter registration first and then scooter owner details. These two factors are highly essential to be verified while purchasing a used scooter. Always take a test ride before you decide to purchase it, and, also don't forget to take a professional mechanic with you who will check the scooter thoroughly and will inform you about any faulty part or damage on the scooter. However, even after a thorough check-up by a professional mechanic, you cannot know the stories related to a second-hand scooter's background.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">What is A Handicap Scooter?</h2>
          <p>A handicap scooter is a wheelchair that is configured like a motor scooter to add mobility aid to those who are unable to walk or stand on their own. It is also recalled as a power-operated scooter or electric scooter as well.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">What is An Electric Scooter?</h2>
          <p>Any scooter that is plug-in electric with two or three wheels and has a step-through frame is an electric scooter. The electricity is stored in a rechargeable battery, which can be utilized to drive one or more electric motors.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">What is Razor Electric Scooter?</h2>
          <p>Razor has combined the style factor with the electric efficiency of E Prime to bring the next generation Razor electric scooter. These scooters have an electric hub motor, high-torque, patent-pending, anti-rattle, folding technology and easy-adjust handlebars.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">What is 3 Wheel Scooter?</h2>
          <p>Any scooter that has three wheels, that can be a handicap, electric or Razor electric scooter, is termed as 3 wheel scooter. Three wheels always provide maximum balancing power, have a wide base for better control over the scooter and are designed to make it light weighted. A 3 wheeled vehicle can be for an infant, an aged or a handicapped person.</p>
          <p>
            All these types of scooters can also be purchased second-hand, but you need to be more careful about its history. They may have more depreciation cost. The various information given by History include the scooter registration details, scooter loan details, scooter blacklist details if any, scooter owner details, and several other information which could be hidden from you, for all types of vehicles. It is important to check scooter history before buying a used scooter as there are many things which we cannot detect just by doing an overall outer body inspection of a second-hand vehicle. There are things that might have happened with the scooter in the past and most of the time the owner would not like to reveal such details. In this case, you may end up buying a scooter that has some dark background and it might put you in trouble. So, always check used scooter history before sealing a deal.
          </p>
        </section>
      </Container>
    </div>
  );
}
