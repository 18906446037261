import React from 'react';
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './static-pages.scss'
export default function CarHistoryReport() {
  return (
    <div id="car-history-report" className="static-page-wrapper">
      <Container className="container">
        <section className="section-wrapper">
          <h1 className="section-heading">Get Car History Report Before Buying Used Car</h1>
          <Button variant="contained" color="primary" href="/" style={{float:'right', color:'#fff', backgroundColor:'#2598fb'}}>Check Car History</Button>
          <p>Buying a car is always full of excitement; be it a brand-new car or a used one, it always gives immense pleasure to have a car of your own. Both new and used cars come with different sets of advantages and disadvantages. Just the way new cars lose their value very fast, used cars are quite hard to rely on. Here, comes the role of car history.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">What is Car History?</h2>
          <p>Car history helps in identifying problems with a used car, so it can also be termed as used car history. Before buying any used car, you can check the free car history report or report that will tell you everything about the used car’s background.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">Why Car History Check is Essential?</h2>
          <p>Today, most of the prospective car buyers are looking to purchase a well-maintained used vehicle over a brand-new one. A used car can be available at a much lesser price than a brand-new one, provided that it is a well-maintained vehicle. If the used car you are going to buy is in good condition and does not require much repair work, then it is always a smart choice to buy a second-hand car. However, before buying a pre-owned car, you need to get it checked by a professional mechanic, as you never know what would have happened to the car earlier. But, apart from this, you should also check car history online to get an in-depth insight.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">What Car Information Needs to be Checked?</h2>
          <p>While several damages and faulty parts are covered under the physical inspection, many things are there which can still be hidden. In this case, you can go for an online car history report and check car information. It gives you an authentic data which makes you more confident about your buying decision. It gives you information on car registration details, loan history of the car, car owner details, fitness report validity of the car, real age of the car, car service history, car accident history, blacklist history (if any), etc.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">Where to Get the Car Information in India?</h2>
          <p>Regional Transport Office or RTO is an organization of Government of India that collects the detailed information of all cars and their owners and maintains a database for all states and union territories of India. RTO differs from one state to another and it is responsible for car registration, bike registration, re-registration, issuing driving license, transferring ownership, collecting tax, etc.</p>
          <p>History by Droom, India’s first and only online marketplace for automobiles and related services, is an online tool that gathers information from RTO and gives a compiled genuine data regarding any car history through its car information report and car history report. All Indian car information is now available under one roof Car History.</p>
        </section>
        <section className="section-wrapper">
          <h2 className="sub-heading">How to get car owner details?</h2>
          <p>Every owner is bound to do car registration under RTO by mentioning all the details. Now, they can opt for the most convenient process of car registration online. When you will decide to purchase a used car, you should review the car registration report. Once, you check car registration details, you will get to know the car owner details as well as details of the car.</p>
        </section>
      </Container>
    </div>
  );
}
